
  <!--template>
    <div class="home-popup-components">
      <div class="popup-content">
        <-- Centrer l'image en tant que fond >
        <div class="popup-image"></div>
        <-- Boutons avec couleur de fond personnalisée >
        <div class="popup-buttons">
          <button class="popup-button" @click='openRegister'>S'inscrire </button>
          <button class="popup-button" @click="loginClicked" >Publier</button>
        </div>
      </div>
    </div>
  </template-->
  <template>
    <div class="popup-container" :class="{ 'hidden': showPopup }">
      <div class="popup-content">
        <button class="close-btn" @click="closePopup" >&times;</button>
        <div class="popup-image"></div>
      
        <button class="left-button" @click='openRegister'>S'inscrire </button>
          <button class="right-button" @click="loginClicked" >Publier</button>
      </div>
</div>
  </template>



  
  <script>
  export default {
    name: 'HomePopupComponents.',
    data() {
     
      return {
        showPopup: false,
        timer: null
      };
    },
    mounted() {
      const popupClosed = localStorage.getItem('popupClosed');
      if (popupClosed === 'true') {
        this.showPopup = true;
      } else {
        this.timer = setTimeout(() => {
          this.showPopup = true;
        }, 10000);
      }

       window.addEventListener('mousemove', this.resetTimer);
    },
    beforeUnmount() {
      window.removeEventListener('mousemove', this.resetTimer);
    },
    methods: {
      handleSignup() {
        // Logique de traitement lors du clic sur le bouton "S'inscrire"
        this.closePopup();
      },
      handlePost() {
        // Logique de traitement lors du clic sur le bouton "Publier"
        this.closePopup();
      },
      closePopup() {
        this.showPopup = false;
        clearTimeout(this.timer);
        localStorage.setItem('popupClosed', 'true');
      },
      resetTimer() {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.showPopup = true;
        }, 10000);
      }
    }
  }
 
  </script>
  
  

  <style scoped>.popup-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
  }
  
  .popup-content {
    position: relative;
    width: 60%;
    height: 60%;
    background-image: url('./../assets/images/chart.jpeg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  
  .close-btn {
    position: absolute;
    top: 10px;
    right: 50px;
    font-size: 24px;
    color: #fff;
    cursor: pointer;
  }
  
  .left-button,
  .right-button {
    position: absolute;
    bottom: 100px;
    padding: 10px 60px;
    background-color: #880000; /* Rouge foncé */
    color: #fff;
    border: none;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
  }
  
  .left-button {
    left: 50px;
  }
  
  .right-button {
    right: 50px;
  }
  
  .left-button:hover,
  .right-button:hover {
    transform: scale(1.1);
  }
  .popup-container.hidden {
  display: none;
}
  
  </style>
  